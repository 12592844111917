
.pagina-contacto{
    margin-top: 100px;
    margin-left: 5%;
    margin-right: 5%;
}

.pagina-contacto h1{
    margin-left: 80px;
}

.tarjeta-contacto{
    width: 33%;
    margin-bottom: 50px;
    text-align: -webkit-center;
}

.tarjetas-contacto{
    width: 100%;
    display: -webkit-inline-box;
    align-content: space-evenly;
}


@media all and (min-width: 650px) and (max-width: 950px){

    .tarjeta-contacto{
        width: 50%;
    }

    
.pagina-contacto h1{
    margin-left: 0px;
}

    .tarjetas-contacto{
        overflow:inherit;
        align-items:center;
    }
}

@media all and (max-width: 649px){
    .pagina-contacto h1{
        margin-left: 0px;
    }

    .tarjetas-contacto{
        display:grid;
        align-content:flex-end;

    }
    .tarjeta-contacto{
        width: 100%;
    }
}