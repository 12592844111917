.App{
    overflow: hidden;
}

.pagina{
    margin-top: 100px;
    margin-left: 150px;
    margin-right: 150px;

}

.foto-contacto{
    max-width: 400px;
    border: solid;
    border-color: gray;
}

.foto-contacto-marco{
    padding-top: 50px;
    margin-left: -30px;

}

.enviar{
padding:15px;
padding-bottom:40px;}

h1{
    font-family: Ruda;
    padding-left: 10px;
}

h2{
    font-family: Ruda;
    padding: 5px
}

h4{
    margin:-2px;
    font-weight: lighter;
    font-size: larger;
}

p{
    font-family: 'Open sans';
    font-size: medium;
    letter-spacing: 1px;
}


@media all and (min-width: 600px) and (max-width: 1023px){
    .App {
        overflow: none;
    }

    .pagina{
        margin-left: 75px;
        margin-right: 75px;
    }
  }


  @media all and (max-width: 600px){
    .App {
        overflow: none;
    }

    .pagina{
        margin-left: 5%;
        margin-right: 10%;
    }
  }