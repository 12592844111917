.landing{
    text-align-last: center;
    position: fixed;

}

.carrusel{
    position: inherit;
}

.imagen-land{
    position: fixed;
    bottom: 0px;
    min-width: 100%;
    min-height: 100%;
    filter: blur(5px);
}

.logo-abasto-land{
    width: 600px;
}

.texto-llegada{
    width: 50%;
    right: 0px;
    font-size: 50px;
    text-align: -webkit-right;
    font-weight:bolder;
    font-family: 'ruda';
}


.fade-in-image { 
    animation: fadeIn 7s; 
    position: fixed;
    right: 10%;
    left: 5%;
    top: 20%;
    text-align: -webkit-right;

    }

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }

  }


  @media all and (min-width: 600px) and (max-width: 1024px){

    .logo-abasto-land{
        width: 400px;
    }
  }
  .texto-llegada{
    font-size: medium;
  }

  @media all and (max-width: 600px){
    .logo-abasto-land{
        width: 90%;
        right: 0%;
    }
    .texto-llegada{
        font-size: medium;
        width: 80%;

      }
  }