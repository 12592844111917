.desc-nosotros{
    line-height: 1.7em;
    text-indent: 1em;
}

.logo-clientes{
    max-width: 250px;
    max-height: 200px;
}


.clientes{
    width: 300px;
    height: 250px;
    align-items: center;
}