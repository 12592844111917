.titulo-detalle{
    font-family: Ruda;
    font-size:large;
}

.texto-serv-detalle{
    width: 60%;
    align-self: center;
}

.texto{
    margin-top: 15px
}

.dialogo{
    text-align: -webkit-center;

}

.img-detalle{
    width: 400px;
    border-radius: 15px;
    border:1px solid #DCA;  
    margin-top: 15px;
}

@media all and (min-width: 600px) and (max-width: 825px){
    .texto-serv-detalle{
        width: 70%;}


}


@media all and (max-width: 600px){
    .texto-serv-detalle{
        width: 80%;}

    .img-detalle{
        width: 80%;
    }
}