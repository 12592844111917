.nav{
height: 50px;
width: 100%;
padding-top: 20px;
padding-bottom: 20px;
margin: 0;
position:fixed;
top: 0px;
backdrop-filter: blur(10px);
z-index: 9;
overflow: hidden;
}

.box{
    position:fixed;
    justify-content: space-between;
    width: 80%;
    left: 10%;
    height: 40px;
    display: flex;
    align-items: center;

}

.titulo{
    font-size: 30px;
    color: #880e4f;
    margin-top: -50px;
    margin-left: 61px;
    
}


.logo img{
    border-radius: 2px;
}

.nav a {
    font-family: 'Raleway', sans-serif;
    text-decoration: none;
    font-weight:bolder;
}

.nav img {
    height: 150px;
    padding-top: -10px;
}


@media all and (min-width: 600px) and (max-width: 1023px){
    .nav img {
        height: 120px;
        padding-top: -10px;
    }
}

@media all and (max-width: 600px){
    .nav img {
        height: 100px;
    }
}