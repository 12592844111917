

.lista-servicio {
    display: flex;
    flex-wrap: wrap;
    place-content: center;

  }

  .radial {
    position: relative;
  margin: 1em auto;
  width: 800px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  margin-top: -120px;
}

.radial_center{
    outline-color: lightgray;
    outline-style: dotted;
    width: 300px;
    aspect-ratio: 1/1;
    position: absolute;
    inset: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    box-shadow: var(--bs);
    border: var(--b-soft);
    z-index: 6;
    display: flex;
    align-items: center;
}

.radial_center_img{
    width:300px;
    z-index: 6;

}


.radial_edge{
    outline-color: lightgray;
    outline-style: dotted;
  width:150px;
  height:150px;
  border-radius:50%;
  position: absolute;
  aspect-ratio: 1/1;
  margin: auto;
  display: flex;
  align-items: center;
}

.radial_edge_img{
  margin-left: -9px;
  width: 170px;
    z-index: 5;
  }

.radial_edge .planeta {
  width: 15px;
  height: 15px;
  background-color: #99CC33;
  border-radius: 50%;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left:0px;
  right:0px;
  overflow: hidden;
  margin: auto;
  -webkit-animation: circle 7s linear infinite;
  animation: circle 7s linear infinite;
  --medium: 75px;
  z-index: 5;
}

.radial_edge_edge{
    
    width:100px;
    height:100px;
    border-radius:50%;
    margin: 1em auto;
    outline-color: lightgray;
    outline-style: dotted;
    position: absolute;
    aspect-ratio: 1/1;
    margin: auto;
    display: flex;
    align-items: center;
  }
  
  .radial_edge_edge_img{
      width: 100px;
      display: flex;
      align-items: center;
  }

  .radial_edge_edge .planeta {
    width: 15px;
    height: 15px;
    background-color: #FFCC33;
    border-radius: 50%;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left:0px;
    right:0px;
    overflow: hidden;
    margin: auto;
    -webkit-animation: circle 5s linear infinite;
    animation: circle 5s linear infinite;
    --medium: 50px;
  }

  .radial_edge_media{
    
    width:130px;
    height:130px;
    border-radius:50%;
    margin: 1em auto;
    outline-color: lightgray;
    outline-style: dotted;
    position: absolute;
    aspect-ratio: 1/1;
    margin: auto;
    display: flex;
    align-items: center;
  }

  .radial_edge_media_img{
    width: 130px;
    display: flex;
    align-items: center;
}

.radial_edge_media .planeta {
  width: 15px;
  height: 15px;
  background-color: #FF6633;
  border-radius: 50%;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left:0px;
  right:0px;
  overflow: hidden;
  margin: auto;
  -webkit-animation: circle 5s linear infinite;
  animation: circle 5s linear infinite;
  --medium: 65px;
}

  @keyframes circle{
    0%{
     transform: rotate(0deg) translate(var(--medium))rotate(0deg);
    }
    100%{
     transform: rotate(360deg) translate(var(--medium))rotate(-360deg)
    }
 }

 

 .tooltip {
  display:flex;
  text-align:left;
  overflow: hidden;

  
}

.tooltip h3 {margin:7px 0;
  size: 18px;}

.left {
  width: 350px;
  top:50%;
  right:100%;
  margin-right:10px;     
  margin-top: 50px;
  transform:translate(0, -50%);
  padding:20px;
  color:#666666;
  background-color:#FFFFE0;
  font-weight:normal;
  border-radius:8px;
  position:absolute;
  z-index:8;
  box-sizing:border-box;
  display:none;
  border:1px solid #DCA;
}

.left p{
  font-size: 16px;
  margin: 3px;
}

.tooltip:hover .left {
  display:block;
  
}

.tooltip .left i {
  position:absolute;
  top:50%;
  left:100%;
  margin-top:-12px;
  width:12px;
  height:24px;
  overflow:hidden;
}

.tooltip .left i::after {
  content:'';
  position:absolute;
  width:12px;
  height:12px;
  left:0;
  top:50%;
  transform:translate(-50%,-50%) rotate(-45deg);
  background-color:#FFFFE0;
  border:1px solid #DCA;
}

.overflow-foto-tooltip{
  overflow: hidden;
  aspect-ratio: 16 / 9;
}

.foto-tooltip{
  width: 100%;
  overflow: hidden;

}

.texto-desc {
  margin: 0px;
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: -10px;
}

/*Ahora va a tocar poner un texto de una linea entero y otro texto que continua entre un div separado que va a ser el que se va a cortar*/

.texto-desc p{
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}




@media all and (min-width: 600px) and (max-width: 825px){
  .radial{
    margin-top: 0px;
  }
  .lista-servicio {
    margin-top: -120px;
    margin-left: 50%;
  }

  .tooltip{
    align-items: center;
    overflow: visible;
  }
  .radial_center, .radial_edge, .radial_edge_edge, .radial_edge_media{
    position:initial;
    transform:none;
      width:150px;
      height:150px;
      margin: 5px;
      margin-top: 300px;
      margin-bottom: 150px;
      align-items: inherit;
  }



  .radial_edge_img, .radial_center_img, .radial_edge_edge_img, .radial_edge_media_img{
    width: 150px;
    height: fit-content;
    margin-left: 0px;
  }
   .radial_edge_edge .planeta, .radial_edge .planeta, .radial_edge_media .planeta {
    animation: none;
    background-color: transparent;

  }

  .left{
    display: block;
    left: 100%;
    width: 400px;
    margin-left: 20px;
    position: static;
    margin-top: 350px;
  }

  .left p{
    font-size: 16px;
  }

  .overflow-foto-tooltip {
    overflow: hidden;
    aspect-ratio: 16/9;
  }


}

@media all and (max-width: 600px){
  .tooltip{
    align-items: center;
    width: 600px;
    overflow: visible;
  }

  .radial{
    text-align: -webkit-center;
  }

  .lista-servicio {
    display:flex;


  }
  .radial_center, .radial_edge, .radial_edge_edge, .radial_edge_media{
    position:initial;
    transform:none;
      width:150px;
      height:150px;
      margin: 5px;
      margin-top: 170px;
      margin-bottom: 250px;
      align-items: inherit;
  }



  .radial_edge_img, .radial_center_img, .radial_edge_edge_img, .radial_edge_media_img{
    width: 150px;
    height: fit-content;
    margin-left: 0px;
  }


   .radial_edge_edge .planeta, .radial_edge .planeta, .radial_edge_media .planeta {
    animation: none;
    background-color: transparent;
  }


  .left{
    display: block;
    height: auto;
    transform: none;
    margin-top: 400px;
    width: 300px;

    position: inherit;
    margin-left: -220px;
  }

  .left p{
    font-size: 12.5px;

  }

  .texto-desc{
    margin: -4px;
    margin-bottom: 5px;
    width: 250px;
    min-width: 0;

  }
  /*Aca tambien tendria que aplicar lo mismo, pero por el momento lo dejamos como estaba antes para que no quede tan feo*/


  .tooltip h3 {
    margin: -2px;
}

.overflow-foto-tooltip {
display: none;
}
  .foto-tooltip{
    display: none;
  
  }


}