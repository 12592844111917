.foto-carousel-clientes{
    overflow: hidden;
    aspect-ratio: 16 / 9;
}

.carousel-clientes{
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
}

.carousel-box-clientes{
    display: -webkit-inline-box;

}


.carousel-clientes-texto{
    font-size: 20px;
    color: black;
    font-family: 'ruda';
    margin-bottom: 0px
}

.clientes-lugar{
    font-size: 15px;
    color: black;
    font-family: 'ruda';
    margin-top: 0px

}

.tarjeta{
    width: 33%;
    background-color:#FFFFE0;
    border-radius: 10px;
    text-align-last: center;
    border-left: 20px;
    height: inherit;
    aspect-ratio: 16/12;
}

.tarjetas{
    width: 100%;
    display: -webkit-inline-flex;
    align-items: center;
    align-content: space-evenly;
}

.fade-in-clientes{
    animation: fadeIn 7s; }

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }

  }

@media all and (min-width: 601px) and (max-width: 949px){

    .carousel-clientes-texto{
        font-size: 15px;
    }



}


@media all and (max-width: 600px){
    .tarjetas{
        display:grid;
        align-content:flex-end;

    }

    .carousel-clientes-texto{
        font-size: 25px;
    }

    .tarjeta{
        width: 100%;
    }



}